# App Initialization

@app = angular.module 'freshSteps', [
  'LocalStorageModule',
  'ui.router',
  'csConfig',
  'ngResource',
  'ngAnimate',
  'ngSanitize',
  'cs-shared',
  'cs.api',
  'textAngular',
  'templates',
  'Scope.safeApply',
  'bc.Flickity',
  'ngCookies'
]

# See app.haml for how these get set
angular.module('csConfig', []).constant('csConfig', window.csConfig)

#
# Some browser extensions like Blur Safari (CHEFWEB-435) and Google Analytics Opt Out (CHEFWEB-443)
# put the DOM in an illegal state where <html> has <script> children. <html> should have only
# <head> and <body>. That in turns causes angular to choke compiling our ng-app on the <html> element.
# So, move anything inappropriate to bottom of <head>.
fixInsaneAngularBrowswerExtensionIncompatibility = ->
  rootKids =  $('html').children()
  head = $('head')
  for kid in rootKids
    name = kid.nodeName
    if (name != 'BODY' && name != 'HEAD')
      head.append(kid)

# We've been getting a lot of errors like https://rollbar.com/ChefSteps/production-chefsteps/items/23899/
# where the global _ object provided by lodash has gone inexplicably missing, so _.contains, _.map
# etc is undefined. This usually will cause the user to experience a white screen of death, as per
# https://app.intercom.io/a/apps/vy04t2n1/inbox/search/conversations/4746275103?q=tomer
# I had an extensive private conversation with him that culminated in his IT department removing viruses
# and now the problem is gone, so my hypothesis is that some virus out there is, as a side effect, destroying
# lodash. This is an attempt at a workaround plus logging. We'll know if it helped if we see fewer (none?)
# of those errors and some of this log firing. If this doesn't work, next step would be to call it more aggresively, not
# just once at start of app - who knows what the virus timing might be.
fixMissingLodash = ->
  if ! window._?
    if ! window.save_underscore
      Rollbar.error("window.save_underscore missing!")
    else
      Rollbar.debug("Lodash missing, restoring")
      window._ = window.save_underscore

@app.config ($stateProvider, $urlRouterProvider, $sceProvider, $locationProvider, csConfig) ->
  fixInsaneAngularBrowswerExtensionIncompatibility()
  fixMissingLodash()

  # Re rewriteLinks, see discussion at https://docs.google.com/spreadsheets/d/1H1S3uM1hQhBujyjiObxzmcRwPFujXPPzwC0a6szx61A/edit#gid=0
  $locationProvider.html5Mode
    enabled: true
    requireBase: false
    rewriteLinks: false

  navView =
    controller: 'NavController as nav'
    templateUrl: "/assets/templates/directives/nav/nav.html"

  footerView =
    templateUrl: "/assets/templates/directives/footer/footer.html"

  galleryView =
    templateUrl: "/assets/templates/pages/gallery/gallery.html"
    controller: 'GalleryController as gallery'

  favourites =
    templateUrl: "/assets/templates/pages/favourites/favourites.html"
    controller: 'favouritesController as gallery'
    data:
      favouritePage: true

  pageViewSlug = (slug) ->
    # nav: navView
    main:
      controller: 'PagesController as page'
      templateUrl: "/assets/templates/pages/show.html"
      data:
        pageId: slug
    # footer: footerView

  pageViewSlugNoFooter = (slug) ->
    # nav: navView
    main:
      controller: 'PagesController as page'
      templateUrl: "/assets/templates/pages/show.html"
      data:
        pageId: slug

  homeViews =
    # nav: navView
    main:
      controller: 'PagesController as page'
      templateUrl: "/assets/templates/pages/show.html"
      data:
        pageId: 'home'
    # footer: footerView

  # Using this as a resolve to ensure that we have prices available before
  # showing pages that might use them, don't want them appearing later.
  loadPricingData = (PricingService) ->
    PricingService.loadData()

  # adminNavView =
  #   controller: 'AdminNavController as nav'
  #   templateUrl: "/assets/templates/admin/adminNav/adminNav.html"

  states =
    '404':
      url: '/404'
      views:
        # nav: navView
        main:
          templateUrl: "/assets/templates/pages/404/404.html"

      # This is an example of how to do a full-page (url) split test.
      # The contents of the variations hash will get merged into the
      # state above.
      experiment:
        name: 'Four Oh Four Fun'
        variations:
          'Control': {}
          'Matrix':
            views:
              # nav: navView
              main:
                templateUrl: "/assets/templates/pages/404/alt404.html"

    'passwordReset':
      url: '/password-reset'
      onEnter: (ModalService) ->
        ModalService.open 'recover'
      views: homeViews

    'main':
      abstract: true
      resolve:
        currentUser: (authenticationService, DigitaldataService, $state, $rootScope, AnalyticsService, UserAccountService) ->
          UserAccountService.removeLegacyStorage()

          authenticationSuccess = (user)->
            # Setting $state.currentUser makes it easier to use currentUser in directives and controllers
            $state.currentUser = user
            AnalyticsService.identify(user)
            DigitaldataService.reload()
            window.digitalData = DigitaldataService.dict
            window.freshpaint.page()
            window.freshpaint.addPageviewProperties({'digitalData': digitalData})
            return user

          authenticationError = (response)->
            window.digitalData = DigitaldataService.dict
            window.freshpaint.page()
            window.freshpaint.addPageviewProperties({'digitalData': digitalData})
            return

          #authenticationService.me().then authenticationSuccess, authenticationError
          UserAccountService.get()
            .then (user) ->
              authenticationSuccess(user['data'])
            .catch (response) ->
              authenticationError(response)

        locationData: (LocationService, ProductGroupsService, $state) ->
          LocationService.loadData()
            .then (data) ->
              $state.locationData = data
              ProductGroupsService.loadData(data)
                .then (response) ->
                  $state.productGroupsData = response.data

        internationalData: (InternationalService, $state) ->
          InternationalService.loadData()
            .then (data) ->
              $state.internationalData = data

        discountData: (DiscountService, $location) ->
          discountId = $location.search().discount_id
          if discountId
            DiscountService.get(discountId)

        flagsData: (FlagsService, $state) ->
          $state.flagsData = FlagsService.index()


      views:
        main:
          templateUrl: "/assets/templates/pages/main.html"

    'main.home':
      url: '/'
      resolve:
        init: ->
          window.location.assign csConfig.chefstepsEndpoint

    'main.sign-in':
      url: '/{path:sign-in|sign_in}',
      onEnter: ($state) ->
        $state.showModal = true

      resolve:
        currentUser: (currentUser) ->
          currentUser

        pricingData: loadPricingData

      views: homeViews

    'logout':
      url: '/logout'
      onEnter: (authenticationService, $state, SpreeAuthenticationService) ->
        authenticationService.logout().then ->
          delete $state['currentUser']
          SpreeAuthenticationService.logout().finally ->
            $state.go 'main.home'

    'main.gallery':
      url: '/gallery?tag&generator&published_status&difficulty&sort&search_all&premium'
      reloadOnSearch: false
      views: pageViewSlugNoFooter('gallery')

    'main.favourites':
      url: '/favorites'
#      resolve:
#        currentAdmin: (currentUser, $state) ->
#          if currentUser?.studio || currentUser?.premium
#            return true
#          else
#            $state.go('main.gallery')
      views:
        # nav: navView
        main: favourites

    # Ugly. the-egg-calculator has never been moved to FS, I think primarily b/c it uses the csFetchTool
    # directive and whatever dependencies that has, and they have never been ported. It is an exception in fresh_steps_proxy.rb,
    # but we still have to deal with it here in case it gets hit via the SPA, such as from the gallery.
    'main.eggCalculatorSpecialCase':
      url: '/activities/the-egg-calculator?context&version'
      onEnter: ($location) ->
        window.location.assign csConfig.chefstepsEndpoint + $location.url()

    'main.activity':
      url: '/activities/:slug?context&version'
      views:
        # nav: navView
        main:
          templateUrl: "/assets/templates/pages/activities/activity.html"
          controller: "ActivityController as activity"
        # footer: footerView
      resolve:
        pricingData: loadPricingData

    'main.gift':
      url: '/gift/:gift_token'
      views:
        # nav: navView
        main:
          templateUrl: "/assets/templates/pages/gift/gift.html"
          controller: "GiftController as gift"
        # footer: footerView

#    Currently getpremium page survey from Chefstep's
#    'main.getpremium':
#      url: '/getpremium'
#      views:
#        nav: navView
#        main:
#          templateUrl: "/assets/templates/pages/getpremium/getpremium.html"
#          controller: "GetpremiumController as getpremium"
#        # footer: footerView

    'main.ordersUpdateAddress':
      url: '/orders/:id/update-address'
      views:
        # nav: navView
        main:
          templateUrl: "/assets/templates/pages/orders/updateAddress.html"
          controller: "updateAddressController as updateAddress"
        # footer: footerView
      resolve:
        currentUser: (currentUser) ->
          currentUser
        customerOrder: (CustomerOrderService, $stateParams) ->
          CustomerOrderService.show {id: $stateParams.id}

    'main.ordersAddressConfirmed':
      url: '/orders/:id/address-confirmed'
      views:
        # nav: navView
        main:
          templateUrl: "/assets/templates/pages/orders/addressConfirmed.html"
          controller: "addressConfirmedController as addressConfirmed"
        # footer: footerView
      resolve:
        addressConfirmed: (CustomerOrderService, $stateParams) ->
          CustomerOrderService.confirmAddress({id: $stateParams.id}).$promise

    'fs_activity':
      url: '/fs_activities/:slug'
      views:
        # nav: navView
        main:
          templateUrl: "/assets/templates/pages/activities/activity.html"
          controller: "ActivityController as activity"
        # footer: footerView

    'main.joule':
      url: '/joule?discountCode'
      resolve:
        pricingData: loadPricingData
        discountCode: (DiscountService, $stateParams) ->
          discountCode = $stateParams.discountCode
          if discountCode?.length > 0
            DiscountService.setCode(discountCode)

      views: pageViewSlug('joule-split-food')

    'main.jouleDiscussion':
      url: '/joule/discussion'
      onEnter: ($location) ->
        $location.path('/forum/categories/joule')

    'main.jouleLanding':
      url: '/joule/:slug'
      resolve:
        pricingData: loadPricingData
      views:
        # nav: navView
        main:
          controller: 'PagesController as page'
          templateUrl: "/assets/templates/pages/show.html"
        # footer: footerView

    'main.gifting':
      url: '/gifting'
      resolve:
        pricingData: loadPricingData
      views: pageViewSlug('gifting')

    'main.jouleContest':
      url: '/preorder-sweepstakes'
      views: pageViewSlug('preorder-sweepstakes')

    'main.jouleContestLegal':
      url: '/preorder-sweepstakes-legal'
      views: pageViewSlug('preorder-sweepstakes-legal')

    'main.about':
      url: '/about'
      views: pageViewSlug('about')

    'main.press':
      url: '/press'
      views: pageViewSlug('press')

    'main.jobs':
      url: '/jobs'
      views: pageViewSlug('new-jobs')

    'main.sso':
      url: '/sso'
      views:
        main:
          controller: 'SSOController as sso'
          templateUrl: "/assets/templates/pages/sso/sso.html"

    'main.recommendedCookbooks':
      url: '/recommended/cookbooks'
      views: pageViewSlug('cookbooks')

    'main.recommendedThermometers':
      url: '/recommended/thermometers'
      views: pageViewSlug('thermometers')

    'main.recommendedSpherificationKits':
      url: '/recommended/spherification-kits'
      views: pageViewSlug('spherification-kits')

    'main.recommendedTools':
      url: '/recommended/tools-we-love'
      views: pageViewSlug('tools-we-love')

    'main.marketPorkshoulder':
      url: '/market/pork-shoulder'
      resolve:
        pricingData: loadPricingData
      views: pageViewSlug('market2')

    'main.marketSalmon':
      url: '/market/salmon'
      resolve:
        pricingData: loadPricingData
      views: pageViewSlug('market-salmon')

    'main.butchersDiscount':
      url: '/butchers/:discountCode'
      resolve:
        pricingData: loadPricingData
        discountCode: (DiscountService, $stateParams) ->
          discountCode = $stateParams.discountCode
          DiscountService.setCode(discountCode)

      views: pageViewSlug('butchers-code')

    'main.newStack':
      url: '/{path:chef-recs|subscriptions|preview|about|jobs|steak-by-joule|smoking-gun|joule-oven-welcome-to-chefsteps|studiopassgiftredeem|classes|guides-tools|recipes}'
      resolve:
        init: ($location) ->
          window.location.assign csConfig.chefstepsEndpoint + $location.url()
    
    'main.pages':
      url: '/:id?premium_code&coupon_code'
      resolve:
        pricingData: loadPricingData

      views:
        # nav: navView
        main:
          controller: 'PagesController as page'
          templateUrl: "/assets/templates/pages/show.html"
        # footer: footerView

    'main.classes':
      url: '/classes/:id'
      resolve:
        pricingData: loadPricingData

      views:
        # nav: navView
        main:
          controller: 'PagesController as page'
          templateUrl: "/assets/templates/pages/show.html"
        # footer: footerView
        data:
          classes: true

    # Redirects any old links back to the class landing page
    'main.classesLanding':
      url: '/classes/:id/{extra:.*}'
      onEnter: ($state, $stateParams, $timeout) ->
        $timeout ->
          $state.go('main.classes', id: $stateParams['id'])

    'main.fs_pages':
      url: '/fs_pages/:id'
      resolve:
        pricingData: loadPricingData
        currentAdmin: (currentUser, $state) ->
          if currentUser.admin
            return true
          else
            $state.go('main.home')

      views:
        # nav: navView
        main:
          controller: 'PagesController as page'
          templateUrl: "/assets/templates/pages/show.html"
        # footer: footerView

    'docs':
      abstract: true
      url: '/docs'
      views:
        main:
          templateUrl: "/assets/templates/pages/docs/index.html"

    'docs.typography':
      url: '/typography'
      views:
        main:
          templateUrl: "/assets/templates/pages/docs/typography.html"

    'docs.components':
      url: '/components'
      views:
        main:
          templateUrl: "/assets/templates/pages/docs/components.html"

    'debug':
      url: '/debug'
      templateUrl: "/assets/templates/pages/debug/index.html"

    'admin':
      url: '/admin'
      resolve:
        currentUser: (authenticationService, $state, $rootScope, AnalyticsService, UserAccountService) ->
          authenticationSuccess = (user)->
            # Setting $state.currentUser makes it easier to use currentUser in directives and controllers
            $state.currentUser = user
            AnalyticsService.identify(user)
            return user

          authenticationError = (response)->
            return

          # authenticationService.me().then authenticationSuccess, authenticationError
          UserAccountService.get()
            .then (user) ->
              authenticationSuccess(user['data'])
            .catch (response) ->
              authenticationError(response)

        locationData: (LocationService, $state) ->
          LocationService.loadData()
            .then (data) ->
              $state.locationData = data

        productGroupsData: (ProductGroupsService, $state) ->
          ProductGroupsService.loadData()
            .then (data) ->
              $state.productGroupsData = data

      views:
        main:
          templateUrl: "/assets/templates/admin/index.html"

    'admin.components':
      abstract: true
      url: '/components'
      resolve:
        currentAdmin: (currentUser, $state) ->
          if currentUser?.admin
            return true
          else
            $state.go('main.home')
      views:
        main:
          template: "<div ui-view='main'><h1>HELLO</h1></div>"

    'admin.components.index':
      url: ''
      views:
        main:
          controller: 'ComponentsIndexController as components'
          templateUrl: "/assets/templates/admin/components/index.html"

    'admin.components.edit':
      url: '/:id/edit'
      views:
        main:
          controller: 'ComponentEditController as editComponent'
          templateUrl: "/assets/templates/admin/components/edit.html"

    'admin.components.new':
      url: '/new?componentParentType&componentParentId'
      views:
        main:
          controller: 'ComponentNewController as newComponent'
          templateUrl: "/assets/templates/admin/components/new.html"

  for state, params of states
    $stateProvider.state state, params

  # Allow /joule/ to go to the same state as /joule
  $urlRouterProvider.when '/joule/', ($state) ->
    $state.go 'main.joule'

  # Route back to rails
  $urlRouterProvider.otherwise ($injector, $location) ->
    window.location.assign csConfig.chefstepsEndpoint + $location.url()

  $urlRouterProvider.deferIntercept()

@app.config ($sceDelegateProvider) ->
  # Allow access to resources when proxied
  $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'http://localhost:4000/**',
      'http://freshsteps-staging.s3-website-us-east-1.amazonaws.com/**',
      'http://freshsteps-production.s3-website-us-east-1.amazonaws.com/**',
      'http://s3.amazonaws.com/freshsteps-staging/**',
      'http://s3.amazonaws.com/freshsteps-production/**',
      'https://s3.amazonaws.com/freshsteps-staging/**',
      'https://s3.amazonaws.com/freshsteps-production/**',
      'http://d2w4l5nz3qs7iy.cloudfront.net/**',
      'https://d2w4l5nz3qs7iy.cloudfront.net/**',
      'http://d3ro0sksttkvbt.cloudfront.net/**',
      'https://d3ro0sksttkvbt.cloudfront.net/**',
      'https://d3874mzg2llbaz.cloudfront.net/**'
  ])

# This makes sure that the 'context' param is carried around from activity to activity even if not explictly specified.
# This could be extended to other uses if needed. You would think you could do this on like $stateChangeStart or something
# but it is too late not to screw up the history.
@app.config ($provide) ->
  $provide.decorator '$state', ($delegate, $location) ->
    state = $delegate
    state.baseTransitionTo = state.transitionTo

    # Decorate the original 'transitionTo' to always copy over the 'context' param
    state.transitionTo = (to, params, options) ->
      if to.name == 'main.activity' && ! params?['context'] && state.params?['context']
        params = params || {}
        params['context'] = params['context'] || state.params?['context']
        # Update the location bar
        $location.replace()
        $location.search(_.extend $location.search(), {context: params['context']})

      # Invoke the original transitionTo
      @baseTransitionTo to, params, options

    $delegate

# Add blob to allow downloading json files for component export feature
@app.config ($compileProvider) ->
  $compileProvider.aHrefSanitizationWhitelist /^\s*(https?|ftp|mailto|file|blob):/

  # Significant performance boost according to: https://docs.angularjs.org/guide/production#disabling-debug-data
  # Renable by angular.reloadWithDebugInfo(); in console
  $compileProvider.debugInfoEnabled(false)

# The Auth0 loading on the NextJS FS application takes time to complete the Auth0 flow.
# Completion of the Auth0 flow is determined by checking the window.isAuthFlowCompleted variable.

@app.run ($rootScope, $urlRouter, $interval, $location, $state) ->
  checkAuthFlowCompleted = ->
    if window.isAuthFlowCompleted
      $interval.cancel(checkInterval)  # Stop the interval
      $urlRouter.listen()      # Start listening to URL changes
      $urlRouter.sync()
      # Optionally, navigate to a default route
      if !$rootScope.$$phase
        $rootScope.$apply ->
          $state.go($state.current, {}, {reload: true})
      else
        $state.go($state.current, {}, {reload: true})

  # Check every 100 milliseconds until `isAuthFlowCompleted` is true
  checkInterval = $interval checkAuthFlowCompleted, 100
