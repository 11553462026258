@app.directive 'anonymousUserPaywall', ($timeout, ModalService, ssoUrlService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'anonymousUserPaywall'
  replace: true

  controller: ($scope) ->
    @createAccountBtn = null

    # Initialize the directive
    init = =>
      @createAccountBtn = {
        type: 'standard',
        title: 'Create Account',
        theme: 'solid orange',
        context: 'Activity Paywall',
        url: @generateSsoLoginURL()
      }

    # Handles the "Join Now" action
    @joinNow = ->
      ModalService.open('signup',
        source: 'payWallButton'
        extraText: "Sign In is required to view this recipe."
      ).then(
        -> $state.go 'main.activity'
      )

    # Generate the SSO login URL
    @generateSsoLoginURL = ->
      "#{ssoUrlService.getSsoUrl()}?path=#{window.location.href}"

    # Handle login action
    @login = ->
      @showMenu = false
      # $timout is needed so create a smooth transition between the nav menu closing and the login modal opening
      $timeout (->
        ModalService.open('login', source: 'nav')
      ), 200

    init()
    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
    source: '='
  }
  templateUrl: "/assets/templates/components/banner/anonymousUserPaywall/anonymousUserPaywall.html"
