@app.service 'ssoUrlService', ($resource, CookieService) ->

  AuthUrlPathConfig =
    us: 'account/en-us/auth'
    ca: 'account/en-ca/auth'
    au: 'account/en-au/auth'
    fr: 'account/en-fr/auth'
    de: 'account/en-de/auth'
    es: 'account/en-es/auth'
    gb: 'account/en-uk/auth'

  defaultLocation = 'us'

  @getSsoUrl = ->
    userLocation = defaultLocation

    geoCookie = CookieService.get('cs_geo')
    if geoCookie
      try
        csGeo = JSON.parse(geoCookie)
        userLocation = _.get(csGeo, 'country', defaultLocation).toLowerCase()
      catch error
        console.error 'Error parsing cs_geo cookie:', error

    locationPath = AuthUrlPathConfig[userLocation] || AuthUrlPathConfig[defaultLocation]
    return "/#{locationPath}"

  this
